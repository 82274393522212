/* =========================
   1) Import a Modern Font
   ========================= */
   @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

   /* ===== GLOBAL RESET & BASE STYLES ===== */
   * {
     margin: 0;
     padding: 0;
     box-sizing: border-box;
   }

   /* Use Montserrat as your primary font */
   html, body {
     font-family: 'Montserrat', sans-serif;
     background-color: #f0f0f0; /* silver-ish background */
     color: #333;
     /* A bit of breathing room for mobile Safari's top/bottom bars */
     padding-top: 1rem;
     padding-bottom: 1rem;
   }

   /* ===== CONTAINER FOR ENTIRE APP ===== */
   .app-container {
     max-width: 600px;
     margin: 0 auto;
     padding: 1rem 1.25rem;
     /* Slight gradient from white to a very light gray */
     background: linear-gradient(to bottom right, #ffffff, #f8f8f8);
     border-radius: 12px; /* Slightly larger rounding for a modern look */
     box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
   }

   /* ===== HEADINGS ===== */
   .main-heading {
     text-align: center;
     margin-bottom: 1.5rem;
     font-size: 1.75rem;
     color: #001f3f; /* Navy */
     font-weight: 700; /* bolder weight from Montserrat */
   }

   .section-heading {
     margin-top: 2rem;
     margin-bottom: 1rem;
     font-size: 1.25rem;
     font-weight: 700;
     color: #001f3f;
   }

   /* ===== FORMS ===== */
   .form {
     margin-bottom: 2rem;
   }

   .form-group {
     display: flex;
     flex-direction: column;
     margin-bottom: 1rem;
   }

   /* Ensure label is above the input with consistent spacing */
   .form-group label {
     margin-bottom: 0.3rem;
     font-weight: 600;
     color: #001f3f;
   }

   .form-control {
     padding: 0.5rem;
     border: 1px solid #ccc;
     border-radius: 4px;
   }

   /*
      Slight highlight on focus for inputs & selects
   */
   .form-control:focus {
     outline: none;
     border-color: #4CAF50; /* Golf green accent when focused */
     box-shadow: 0 0 4px rgba(76, 175, 80, 0.3);
   }

   /*
      .form-inline for Time Min/Max
      We'll keep them side-by-side on bigger screens,
      stacking on small screens via the media query below.
   */
   .form-inline {
     display: flex;
     flex-wrap: wrap;
     gap: 1rem;
     margin-bottom: 1rem;
   }

   /* Each label+input pair is in .inline-item.
      We'll align them horizontally & center vertically.
   */
   .form-inline .inline-item {
     display: flex;
     flex-direction: row;
     align-items: center;
     gap: 0.5rem;
   }

   .form-inline .inline-item label {
     margin-bottom: 0;
     font-weight: 600;
     color: #001f3f;
   }

   /* ===== BUTTONS ===== */
   .btn {
     display: inline-block;
     padding: 0.75rem 1.5rem;
     border: none;
     border-radius: 4px;
     cursor: pointer;
     font-weight: 600;
     transition: background-color 0.2s ease, box-shadow 0.2s ease;
     text-align: center;
   }

   .btn-primary {
     background-color: #001f3f; /* Navy */
     color: #fff;
   }

   .btn-primary:hover {
     background-color: #001a35; /* Darker navy on hover */
     box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
   }

   .btn-green {
     background-color: #4CAF50; /* Golf green accent */
     color: #fff;
   }

   .btn-green:hover {
     background-color: #43a047; /* Slightly darker green */
     box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
   }

   /* ===== LOADING / ERROR / SUCCESS TEXT ===== */
   .loading-text {
     color: #001f3f; /* Navy */
     font-weight: 600;
   }

   .error-text {
     color: red;
     margin-top: 0.5rem;
   }

   .success-text {
     color: green;
     margin-top: 0.5rem;
   }

   /* NEW Notice Text for "No Available Tee Times" */
   .notice-text {
     color: #555;
     font-weight: 600;
     margin-top: 0.5rem;
   }

   /* ===== SEARCH RESULTS LIST ===== */
   .results-list {
     list-style: none;
     padding-left: 0;
     margin-top: 1rem;
   }

   .results-list li {
     padding: 0.5rem 0;
     border-bottom: 1px solid #ddd;
   }

   .results-list li strong {
     color: #001f3f; /* Navy for times */
     font-weight: 600;
   }

   /* ===== MEDIA QUERIES ===== */
   @media screen and (max-width: 600px) {
     /* Slightly less margin on small screens */
     .app-container {
       margin: 0.5rem;
       padding: 1rem;
     }

     /* Stack inline items on smaller screens */
     .form-inline {
       flex-direction: column;
       align-items: flex-start;
       gap: 0.5rem;
     }
   }
